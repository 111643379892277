* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  background-color: #FFF7ED;
  font-family: "Inter", sans-serif;
}

body::-webkit-scrollbar {
display: none;
}

body {
-ms-overflow-style: none;
scrollbar-width: none;
}

header {
  height: 110px;
  display: flex;
  align-items: center;
  padding-block: 10px;
}

header .site-logo {
  color: black;
  margin-right: auto;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 25px;
}

header a {
  text-decoration: none;
  color: #4D4D4D;
  font-weight: 600;
  padding: 5px 12px;
}

header a:hover {
  color: #161616;
  text-decoration: underline;
}

.link-button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border: none;
  padding: .75rem 1.375rem;
  color: initial;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  transition: transform .1s ease-in-out;
}

.link-button:hover {
  transform: translate(1px, 1px);
}

/*** HOME PAGE ***/
.home-container {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.46), rgba(0, 0, 0, 0.46)), url("./Assets/images/home-hero.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  min-height: 350px;
  color: white;
  padding: 45px 23px;
}

.home-container h1 {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 42px;
}

.home-container p {
  line-height: 24px;
}

.home-container a {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background-color: #FF8C38;
  border: none;
  width: 100%;
  margin-top: 27px;
  padding-block: .75rem;
  color: white;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  transition: transform .1s ease-in-out;
}

.home-container a:hover {
  transform: translate(1px, 1px);
}


/*** ABOUT PAGE ***/
.about-hero-image {
  max-width: 100%;
}

.about-page-content {
  padding-inline: 23px;
  color: #161616;
  margin-bottom: 55px;
}

.about-page-content h1 {
  line-height: 38px;
}

.about-page-content p {
  line-height: 22px;
}

.about-page-cta {
  background-color: #FFCC8D;
  color: #161616;
  padding-inline: 32px;
  padding-bottom: 32px;
  margin-inline: 27px;
  border-radius: 5px;
}

.about-page-cta h2 {
  margin: 0;
  padding-block: 37px;
}

.about-page-cta .link-button {
  background-color: #161616;
  color: white;
  border-radius: 10px;
}

/*** VAN PAGES ***/
.van-list-container {
  padding-inline: 23px
}

.van-list-filter-buttons {
  display: flex;
  flex-wrap: wrap;
}

.van-list-filter-buttons > a {
  text-decoration: unset;
  margin-right: 10px;
  font-weight: 600;
}

.van-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  gap: 34px;
  margin-top: 57px;
}

.van-tile a {
  color: #161616;
  text-decoration: none;    
}

.van-tile img {
  max-width: 100%;
  border-radius: 5px;
}

.van-list-container button {
  margin-right: 20px;
  cursor: pointer;
}

.van-type {
  height: 34px;
  padding: 6px 26px;
  font-style: normal;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  background-color: #FFEAD0;
  color: #4D4D4D;
  transition: 200ms all cubic-bezier(0.4, 0, 0.2, 1);
}

.van-type:hover {
  color: #FFEAD0;
}

.van-type:focus {
  outline: none;
}

.van-type.selected {
  color: #FFEAD0;
}

.van-type.simple:hover, .van-type.simple.selected {
  background-color: #E17654;
}

.van-type.rugged:hover, .van-type.rugged.selected {
  background-color: #115E59;
}

.van-type.luxury:hover, .van-type.luxury.selected {
  background-color: #161616;
}

.van-type.clear-filters {
  text-decoration: unset;
  margin-left: -20px;
  height: 34px;
  padding: 6px 26px;
  font-style: normal;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  text-decoration: underline;
  background-color: transparent;
  color: #4D4D4D;
}

i[class*='van-type-'] {
  padding: 7px 14px;
}.van-type:hover {
  color: #FFEAD0;
}

.van-type:focus {
  outline: none;
}

.van-type.selected {
  color: #FFEAD0;
}

.van-type.simple:hover, .van-type.simple.selected {
  background-color: #E17654;
}

.van-type.rugged:hover, .van-type.rugged.selected {
  background-color: #115E59;
}

.van-type.luxury:hover, .van-type.luxury.selected {
  background-color: #161616;
}

.van-type.clear-filters {
  margin-left: -20px;
  height: 34px;
  padding: 6px 26px;
  font-style: normal;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  text-decoration: unset;
  background-color: transparent;
  color: #4D4D4D;
}

i[class*='van-type-'] {
  padding: 7px 14px;
}


/*** Individual van page ***/

.van-detail-container {
  padding: 27px;
}

.van-detail-container .back-button {
  color: black;
  text-decoration: none;
}

.van-detail-container .back-button span {
  text-decoration: underline;
}

.van-detail {
  display: flex;
  flex-direction: column;
  color: #161616;
}

.van-detail img {
  border-radius: 5px;
  margin-block: 47px;
}

.van-detail h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.van-detail > i {
  align-self: flex-start;
}

.van-detail .van-price {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.van-detail .van-price span {
  font-weight: 700;
  font-size: 1.5rem;
}

.van-detail .link-button {
  background-color: #FF8C38;
  color: white;
  font-size: 1.125rem;
}
/*  layout 2 navigation */

a.nav-active{
  font-weight: bold;
  text-decoration: underline;
  color: #161616;
}
.host-nav {
  display: flex;
  margin-bottom: 20px;
}

.host-nav a {
  text-decoration: none;
  color: #4D4D4D;
  font-weight: 500;
  padding: 5px 20px;
}

.host-nav a:hover {
  color: #161616;
  text-decoration: underline;
  font-weight: 600;
}

/*** Footer ***/
.site-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
main {
  margin-bottom: 84px;
}
footer {
  background-color: #161616;
  color: #AAAAAA;
  height: 74px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  font-weight: 500;
}

/** vanlist **/
.host-van-link-wrapper {
  color: unset;
  text-decoration: unset;
}

.host-vans-list {
  padding-inline: 26px;
}

.host-vans-title {
  padding-inline: 26px;
}

.host-van-single {
  display: flex;
  align-items: center;
  background-color: white;
  margin-bottom: 15px;
  padding-block: 18px;
  padding-left: 24px;
}

.host-van-single > img {
  height: 70px;
  border-radius: 5px;
  margin-right: 17px;
}

.host-van-info h3 {
  font-size: 20px;
  font-weight: 600;
  margin-block: 10px;
}

.host-van-info p {
  margin-block: 10px;
}

/** host van detail page**/
.host-van-detail-layout-container {
  background-color: white;
  padding: 24px;
  margin: 30px 26px;
}

.host-van-detail {
  display: flex;
  align-items: center;
}

.host-van-detail > img {
  height: 160px;
  border-radius: 5px;
  margin-right: 20px;
}

.host-van-detail-info-text > h3 {
  margin-top: 24px;
  margin-bottom: 5px;
  font-size: 26px;
  font-weight: 700;
}

.host-van-detail-info-text > h4 {
  font-size: 20px;
  margin-block: 0;
}
.back-button {
  display: block;
  color: black;
  text-decoration: none;
  margin-top: 60px;
  margin-left: 26px;
}
.nav-active{
  font-weight: "bold";
  text-decoration: underline;
  color: "#161616";
}
/** host-van-detail-nav **/
.host-van-detail-nav {
  display: flex;
  margin-block: 25px;
}

.host-van-detail-nav a {
  text-decoration: none;
  color: #4D4D4D;
  font-weight: 500;
  padding: 5px 20px;
}

.host-van-detail-nav a:first-of-type {
  text-decoration: none;
  color: #4D4D4D;
  font-weight: 500;
  padding: 5px 15px;
  margin-left: -15px;
}

.host-van-detail-nav a:hover {
  color: #161616;
  text-decoration: underline;
  font-weight: 700;
}
.host-van-detail-info > h4 {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}
.host-van-detail-info > h4 > span {
  font-weight: 500;
}
.host-van-detail-image {
  height: 100px;
  border-radius: 5px;
}
.host-van-price {
  font-size: 24px;
  font-weight: 500;
}
.host-van-price > span {
  font-size: 16px;
  color: #4D4D4D;
}
.not-found-container {
  padding-inline: 26px;
}

.not-found-container .link-button {
  width: 100%;
  background-color: #161616;
  color: white;
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 27px;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;    
}

.login-form > input {
  border: 1px solid rgba(209, 213, 219);
  height: 40px;
  text-indent: 10px;
  font-family: "Inter", sans-serif;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-weight: 400;
}

.login-form > input:focus {
  outline: none;
}

.login-form > input:first-of-type {
  border-radius: 6px 6px 0px 0px;
}

.login-form > input:last-of-type {
  border-radius: 0px 0px 6px 6px;
  border-top: none;
}

.login-form > button {
  background-color: #FF8C38;
  border: none;
  border-radius: 6px;
  height: 55px;
  margin-top: 22px;
  color: white;
  font-family: "Inter", sans-serif;
}
.red {
  color: #f90505;
  text-align: center;
}
.red-none{
  text-align: center;
}
.profile-img{
  height: 20vh;
  border-radius: 50%;
  border: solid 3px black;
}
.email-host{
  font-weight: bold;
}
.button-profile {
  background-color: #FF8C38;
  border: none;
  border-radius: 9px;
  height: 60px;
  width: 200px;
  margin-top: 22px;
  color: white;
  font-weight: bold;
  font-family: "Inter", sans-serif;
}
.host-dashboard-earnings {
  background-color: #ffead0;
  padding: 37px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.host-dashboard-earnings > .info > h1 {
  font-size: 36px;
  color: #161616;
  margin: 0;
}

.host-dashboard-earnings > .info > p {
  color: #4d4d4d;
}

.host-dashboard-earnings > .info > p > span {
  text-decoration: underline;
  font-weight: bold;
}

.host-dashboard-earnings > .info > h2 {
  margin: 0;
  font-size: 38px;
  color: #161616;
  font-weight: 900;
}

.host-dashboard-reviews {
  background-color: #ffddb2;
  padding: 25px 26px;
  display: flex;
  align-items: center;
}

.host-dashboard-reviews > h2 {
  margin: 0;
}

.host-dashboard-reviews .star {
  color: #ff8c38;
  margin-left: 15px;
  font-size: 25px;
}

.host-dashboard-reviews p {
  margin-left: 5px;
  font-size: 20px;
  color: #4d4d4d;
  margin-right: auto;
}

.host-dashboard-reviews p > span {
  font-weight: bold;
  color: #161616;
}

.host-dashboard-vans {
  padding: 38px 26px;
}

.host-dashboard-vans > .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.host-dashboard-vans .host-vans-list {
  padding-inline: 0;
}

.host-income {
  padding-inline: 26px;
  color: #161616;
}

.host-income > p {
  color: #4d4d4d;
}

.host-income > p span {
  text-decoration: underline;
  font-weight: bold;
}

.host-income > h2 {
  font-size: 38px;
  color: #161616;
  font-weight: 900;
}

img.graph {
  width: 100%;
  max-width: 500px;
}

.host-income .info-header > p {
  color: #4d4d4d;
}

.host-income .info-header > p span {
  text-decoration: underline;
  font-weight: bold;
}

.host-income .info-header > h3 {
  font-weight: 700;
  font-size: 24px;
  margin-top: 60px;
}

.host-income .transaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin-bottom: 31px;
  padding: 35px 29px;
  border-radius: 6px;
}
.host-income .transaction > h3 {
  font-size: 36px;
  font-weight: 600;
  margin: 0;
}

.host-income .transaction > p {
  font-weight: 500;
  font-size: 20px;
  color: #4d4d4d;
}

.host-reviews {
  padding-inline: 26px;
  color: #161616;
}

.host-reviews .top-text {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.host-reviews .top-text > h2 {
  font-weight: 700;
  font-size: 32px;
  margin: 0;
  margin-right: 20px;
}

.host-reviews .top-text > p {
  margin: 0;
  color: #4d4d4d;
}

.host-reviews .top-text > p span {
  text-decoration: underline;
  font-weight: bold;
}

.host-reviews > img {
  margin-bottom: 36px;
}

.host-reviews .review {
  margin-block: 25px;
}

.host-reviews .review .info {
  display: flex;
}

.host-reviews .review .name {
  color: #161616;
  margin-right: 10px;
  font-weight: 600;
}

.host-reviews .review .date {
  color: #8c8c8c;
}

.host-reviews hr {
  background-color: #c7c7c7;
  height: 1px;
  border: 0;
}

.host-reviews .review-star {
  color: #ff8c38;
  margin-right: 5px;
}
